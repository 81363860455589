import { cartEventPublisher, cartEventSubscriber } from "../../common/pubsub/cart-event-pub-sub.js";
import { CartItemsResultEventData, CartItemsAddedResultEventData, CartItemsRemovedResultEventData} from "../../common/pubsub/models/cart-items-result-event-data.js";
import { CartService } from "./cart-service.js";
import { CartControllerUtils } from "./cart-controller-utils.js";
import { IDetailedCartInfo, IDetailedCartInfoForUi } from "./interfaces/detailed-cart-info.js";
import { TempOrderLineProductContractUiHelper, TempOrderLineProductContract } from "./interfaces/temp-order-line-product-contract.js";
import { OrderLineType } from "../../../components-shared/enums/order-line-type.js";
import { FreeShippingApi } from "./free-shipping-api.js";

export class SiteCartController {
    private static instance: SiteCartController;

    constructor() {
        if(!window.cartSettings) throw Error("Cart settings are not defined");

        if (!SiteCartController.instance) {
            SiteCartController.instance = this;
            this._getData();
            this.initEventSubscibtions();
        }
        return SiteCartController.instance;
    }

    /* #region Public */
    public initEventSubscibtions(){
        cartEventSubscriber.onCartItemsAdded(this._onCartItemsAdded.bind(this));
        cartEventSubscriber.onCartItemsRemoved(this._onCartItemsRemoved.bind(this));
        cartEventSubscriber.onCartEmptied(this._onCartEmptied.bind(this));
        cartEventSubscriber.onCartQuantityUpdated(this._onCartQuantityUpdated.bind(this));
    }

    public getSiteCartInfo(): IDetailedCartInfo | undefined{
        return this._siteCartInfo;
    }

    public async removeLine(line: TempOrderLineProductContract){
        try{
            await this._cartService.removeFromCart(line.tempOrderLineID);
        } catch(error) {
            console.log('Remove orderline error', error);
        }
    }

    public async clearCart(){
        try{
            await this._cartService.emptyCart();
        } catch(error) {
            console.log('Clear cart failed', error);
        }
    }
    /* #endregion */

    /* #region Event handlers */
    private async _onCartQuantityUpdated(topic: string, eventData: CartItemsResultEventData){
        await this._updateSiteCartInfo(eventData.result.detailedCartInfo);
    }

    private async _onCartItemsAdded(topic: string, eventData: CartItemsAddedResultEventData){
        await this._updateSiteCartInfo(eventData.result.detailedCartInfo);
    }

    private async _onCartItemsRemoved(topic: string, eventData: CartItemsRemovedResultEventData){
        await this._updateSiteCartInfo(eventData.result.detailedCartInfo);
    }

    private async _onCartEmptied(topic: string, eventData: CartItemsRemovedResultEventData){
        await this._updateSiteCartInfo(eventData.result.detailedCartInfo);
    }
    /* #endregion */

    /* #region Private */
    private _inclVat: boolean = window.globalSettings.displayPricesIncludingVat;
    private _siteCartInfo: IDetailedCartInfoForUi | undefined = undefined;
    private _cartService = new CartService();
    private _utils = new CartControllerUtils();
    private _freeShipipngApi = new FreeShippingApi();

    private _getData(): void{
        this._cartService.getSiteCartInfo()
            .then((detailedCartInfo) => {
                this._updateSiteCartInfo(detailedCartInfo);
            })
    }

    private _publishDetailedCartChangedEvent() {
        cartEventPublisher.siteCartChanged(this._siteCartInfo);
    }

    private _isOrderLineTypeText(lineType: OrderLineType): boolean { ///TODO: move to utils but not controller? Review after order-line refactoring
        return lineType === OrderLineType.ExtraField ||
               lineType === OrderLineType.InscriptionTextLine;
    }

    private async _updateSiteCartInfo(data: IDetailedCartInfo) {
        if (data) {
            const freeShippingInfo = await this._freeShipipngApi.getFreeShippingData(this._orderSumAmount(data)); //TODO: to use this record and avoid an extra request to FreeShippingApi - we need get propper prices from the server at freeshippinginfo contract (inclVat or/and exclVat).

            this._siteCartInfo = {...data} as IDetailedCartInfoForUi;
            this._siteCartInfo.products = [... this._excludeProductsFromSiteCart(this._siteCartInfo.products)];
            this._siteCartInfo.products = [... this._utils.extendDataForUi(this._siteCartInfo.products)];
            this._siteCartInfo.products = [... this._utils.setGroupingParams(this._siteCartInfo.products, true)];
            this._siteCartInfo.freeShippingInfo = freeShippingInfo;

            this._publishDetailedCartChangedEvent();
        }
    }

    private _orderSumAmount(data: IDetailedCartInfoForUi){
        return this._inclVat ? data.summary.totalPrice : data.summary.totalPriceExclVat;
    }

    private _excludeProductsFromSiteCart(products: TempOrderLineProductContractUiHelper[]): TempOrderLineProductContractUiHelper[]{
        this._siteCartInfo.products = products.filter(
            product => !this._isOrderLineTypeText(product.lineType) && product.parentID === 0 || (product.parentID > 0 && product.offerID && product.offerID > 0)
        );
        return this._siteCartInfo.products;
    }
    /* #endregion */
}